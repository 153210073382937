import React from "react";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Back button icon
import EditIcon from "@mui/icons-material/Edit"; // Edit button icon
import InfoIcon from "@mui/icons-material/Info"; // General Information icon
import SettingsIcon from "@mui/icons-material/Settings"; // Account Settings icon

const ProfileDetails = ({ isVisible, onClose }) => {
  const location = useLocation();
  const { imageUrl, firstName, lastName, email } =
    JSON.parse(localStorage.getItem("user")) || {};

  return (
    <div
      className={`fixed top-0 right-0 h-screen w-1/3 bg-white shadow-lg transform transition-transform duration-300 ${
        isVisible ? "translate-x-0" : "translate-x-full"
      } overflow-y-auto`}
    >
      <div className="bg-pageBackground flex flex-col h-screen">
        {/* Header Section */}
        <div className="flex items-center p-4">
          <button
            onClick={onClose}
            className="bg-gray-100 rounded-full size-10 p-2 flex items-center"
          >
            <ArrowBackIcon className="text-actionColor text-2xl" />
          </button>
          <h2 className="text-2xl font-bold ml-6">Profile Detail</h2>
        </div>

        {/* Profile Header Section */}
        <div className="bg-componentBackground p-4 rounded-[10px] shadow-sm mt-2 mb-6 mx-4">
          <div className="flex items-center justify-end mb-4">
            <button className="bg-indigo-100 text-sm font-medium text-actionColor rounded-md py-1 px-2 flex items-center">
              <EditIcon className="mr-1" style={{ fontSize: "14px" }} />
              Edit
            </button>
          </div>
          <div className="mt-4">
            {/* Profile Image and User Info */}
            <div className="flex items-center">
              <img
                alt="Profile"
                src={imageUrl}
                className="w-16 h-16 rounded-full border border-gray-300"
              />
              <div className="ml-4">
                <h3 className="text-lg font-semibold text-fontColor">
                  {`${firstName} ${lastName}`}
                </h3>
                <p className="text-sm font-medium text-gray-500">{email}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Profile Details Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mx-4 mb-4">
          {/* General Information */}
          <button className="bg-componentBackground p-6 rounded-[10px] shadow-sm transition duration-300 hover:shadow-lg hover:bg-indigo-100">
            <div className="flex items-center">
              <InfoIcon className="mr-2 text-actionColor" />
              <h3 className="text-md font-normal text-black opacity-70">
                General Info
              </h3>
            </div>
          </button>

          {/* Account Settings */}
          <button className="bg-componentBackground p-6 rounded-[10px] shadow-sm transition duration-300 hover:shadow-lg hover:bg-indigo-100">
            <div className="flex items-center">
              <SettingsIcon className="mr-2 text-actionColor" />
              <h3 className="text-md font-normal text-black opacity-70">
                Account Settings
              </h3>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
