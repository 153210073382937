import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const LineGraph = () => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "User Analytics",
        data: [100, 200, 150, 220, 300, 250, 400, 350, 30, null, null, null],
        fill: "start", // Fill the area under the line
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, chartArea.width, 0);
          gradient.addColorStop(0, "rgba(85, 143, 255, 0.2)"); // Light blue at start
          gradient.addColorStop(1, "rgba(194, 84, 255, 0.2)"); // Light purple at end
          return gradient;
        },
        borderColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, chartArea.width, 0);
          gradient.addColorStop(0, "#558FFF"); // Blue
          gradient.addColorStop(1, "#C254FF"); // Purple
          return gradient;
        },
        borderWidth: 3,
        pointRadius: 5,
        pointHoverRadius: 7,
        pointBackgroundColor: "#FFFFFF",
        pointBorderColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, chartArea.width, 0);
          gradient.addColorStop(0, "#558FFF"); // Blue
          gradient.addColorStop(1, "#C254FF"); // Purple
          return gradient;
        },
        pointBorderWidth: 2,
        tension: 0.4, // Add curvature to the lines
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      title: {
        display: true,
        text: "Monthly Sales",
        font: {
          size: 18,
          family: "'Nunito', sans-serif",
          weight: 700,
        },
        color: "rgba(3, 2, 41, 0.7)", // #030229 with 70% opacity
        align: "start", // Align title to the left
        lineHeight: "24.55px",
        padding: {
          top: 2,
          bottom: 20,
        },
      },
      tooltip: {
        backgroundColor: "#0A0935",
        titleColor: "#FFFFFF",
        bodyColor: "#FFFFFF",
        cornerRadius: 10,
        padding: 10,
      },
    },
    scales: {
      y: {
        grid: {
          color: "rgba(200, 200, 200, 0.1)", // Light grid lines
        },
        ticks: {
          beginAtZero: true,
          font: {
            size: 12,
            family: "'Nunito', sans-serif",
            weight: 400,
          },
          color: "rgba(3, 2, 41, 0.7)", // #030229 with 70% opacity
        },
      },
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
        ticks: {
          font: {
            size: 12,
            family: "'Nunito', sans-serif",
            weight: 400,
          },
          color: "rgba(3, 2, 41, 0.7)", // #030229 with 70% opacity
        },
      },
    },
  };

  return (
    <div
      className="w-full bg-white shadow-sm rounded-[10px] p-6 font-nunito"
      style={{
        height: "400px",
        border: "1px solid #5B93FF0D", // Updated border color
      }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default LineGraph;
