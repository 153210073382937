import React from "react";
import { Link } from "react-router-dom";

const SidebarLink = ({ isCollapsed, icon: Icon, label, path, isActive }) => (
  <Link
    to={path}
    className={`flex items-center p-4 transition-all ${
      isActive
        ? "bg-[linear-gradient(90deg,#D1C6FF_0%,rgba(172,169,255,0)_12%)] text-actionColor"
        : "text-fontColor"
    }`}
  >
    <Icon
      className={`w-6 h-6 transition-colors duration-200 ${
        isActive
          ? "text-actionColor"
          : "text-fontColor opacity-50 hover:text-actionColor"
      }`} //icon
    />
    {!isCollapsed && (
      <span
        className={`ml-4 text-base font-semibold transition-colors duration-200 ${
          isActive
            ? "text-actionColor"
            : "text-fontColor opacity-50 hover:text-actionColor"
        }`}
      >
        {label}
      </span>
    )}
  </Link>
);

export default SidebarLink;
